import apiClient from "@/services/axios"

export const getRoomClass = async (query) => {
  const res = await apiClient.get(`/api/room-class`)
  if (res) {
    return res
  } else {
    return null
  }
}
export const getRoom = async (query) => {
  const res = await apiClient.get(`/api/room`)
  if (res) {
    return res
  } else {
    return null
  }
}

export const getRoomClassById = async (id) => {
  const res = await apiClient.get(`/api/room-class/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}
export const getRoomById = async (id) => {
  const res = await apiClient.get(`/api/room/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}

export const createRoomClass = async (
  jenisPerawatan,
  namaKelasKamar,
  bhpRuangan,
  biayaAdministrasi,
  tarifKelasKamarUmum,
  tarifKelasKamarBPJS,
  tarifKelasKamarAsuransiLain,
  tarifPerawatanUmum,
  tarifPerawatanBPJS,
  tarifPerawatanAsuransiLain,
) => {
  const res = await apiClient.post(`/api/room-class`, {
    jenisPerawatan,
    name: namaKelasKamar,
    bhpRuangan,
    biayaAdministrasi,
    tarifKelasKamarUmum,
    tarifKelasKamarBPJS,
    tarifKelasKamarAsuransiLain,
    tarifPerawatanUmum,
    tarifPerawatanBPJS,
    tarifPerawatanAsuransiLain,
  })
  if (res) {
    return res
  } else {
    return null
  }
}
export const createRoom = async (data) => {
  const res = await apiClient.post(`/api/room`, data)
  if (res) {
    return res
  } else {
    return null
  }
}
export const updateRoom = async (data, id) => {
  const res = await apiClient.put(`/api/room/` + id, data)
  if (res) {
    return res
  } else {
    return null
  }
}
export const updateRoomClass = async (
  jenisPerawatan,
  namaKelasKamar,
  bhpRuangan,
  biayaAdministrasi,
  tarifKelasKamarUmum,
  tarifKelasKamarBPJS,
  tarifKelasKamarAsuransiLain,
  tarifPerawatanUmum,
  tarifPerawatanBPJS,
  tarifPerawatanAsuransiLain,
  id,
) => {
  const res = await apiClient.put(`/api/room-class/` + id, {
    jenisPerawatan,
    name: namaKelasKamar,
    bhpRuangan,
    biayaAdministrasi,
    tarifKelasKamarUmum,
    tarifKelasKamarBPJS,
    tarifKelasKamarAsuransiLain,
    tarifPerawatanUmum,
    tarifPerawatanBPJS,
    tarifPerawatanAsuransiLain,
  })
  if (res) {
    return res
  } else {
    return null
  }
}

export const activateRoomClass = async (id) => {
  const res = await apiClient.put(`/api/room-class/active/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}
export const deactivateRoomClass = async (id) => {
  const res = await apiClient.put(`/api/room-class/inactive/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}
export const activateRoom = async (id) => {
  const res = await apiClient.put(`/api/room/active/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}
export const deactivateRoom = async (id) => {
  const res = await apiClient.put(`/api/room/inactive/` + id)
  if (res) {
    return res
  } else {
    return null
  }
}
