<template>
  <div class="row">
    <div class="col-12">
      <h5 style="color: #428a94; font-weight: bold">Tambah Kelas Kamar</h5>
      <h6 style="font-weight: 500" class="mt-5">Informasi Umum</h6>
      <div class="row">
        <div class="col-4 col-sm-12 my-4">
          <p>Jenis Perawatan<span style="color: #dc3545">*</span></p>
          <a-radio-group
            :options="jenisPerawatanOptions"
            v-model="jenisPerawatan"
          />
        </div>

        <div class="col-12 mt-4">
          <p class="m-0">
            Nama Kelas Kamar<span style="color: #dc3545">*</span>
          </p>
          <span class="m-0" style="font-size: 12px; color: #667085">
            Nama harus unik dari nama kelas kamar lain di master kelas kamar
          </span>
          <div class="row px-3 mt-2">
            <a-input
              placeholder="Nama Kelas Kamar"
              class="col-12 col-sm-6 col-md-4"
              v-model="namaKelasKamar"
            ></a-input>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mt-3" v-if="!isNamaKelasKamarEmpty">
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Nama Kelas Kamar masih kosong.
        </p>
      </div>
      <div class="d-flex align-items-center mt-3" v-if="!isNamaKelasKamarExist">
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Sudah ada Kelas Kamar dengan nama ini.
        </p>
      </div>
      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">BHP Ruangan</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="bhpRuangan"
              placeholder="0"
              @input="filterInput('bhp')"
            ></a-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">Biaya Administrasi</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="biayaAdministrasi"
              placeholder="0"
              @input="filterInput('biaya')"
            ></a-input>
          </div>
        </div>
      </div>
      <a-divider></a-divider>
      <h6 style="font-weight: 500" class="mt-5">Tarif Kelas Kamar</h6>

      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">Umum/Mandiri</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifKelasKamarUmum"
              placeholder="0"
              @input="filterInput('tr-umum')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-0">
          <p class="">BPJS</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifKelasKamarBPJS"
              placeholder="0"
              @input="filterInput('tr-bpjs')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-3">
          <p class="">Asuransi Lainnya</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifKelasKamarAsuransiLain"
              placeholder="0"
              @input="filterInput('tr-lainnya')"
            ></a-input>
          </div>
        </div>
      </div>
      <a-divider></a-divider>
      <h6 style="font-weight: 500" class="mt-5">Tarif Perawatan Per Hari</h6>

      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">Umum/Mandiri</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifPerawatanUmum"
              placeholder="0"
              @input="filterInput('tp-umum')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-0">
          <p class="">BPJS</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifPerawatanBPJS"
              placeholder="0"
              @input="filterInput('tp-bpjs')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-3">
          <p class="">Asuransi Lainnya</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifPerawatanLainnya"
              placeholder="0"
              @input="filterInput('tp-lainnya')"
            ></a-input>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12">
          <a-button
            type="primary"
            class="mt-2 d-flex flex-row align-items-center justify-content-center"
            style="width: 230px; border-radius: 26px"
            size="large"
            @click="handleAddModal"
          >
            <img
              src="resources/images/save.png"
              alt="Icon"
              style="height: 18px; width: 18px"
            />
            <span style="margin-left: 5px">Simpan Kelas Kamar</span></a-button
          >
        </div>
      </div>
      <a-modal
        v-model="modalAddVisible"
        title="Tambah kelas kamar"
        centered
        :footer="null"
      >
        <p>
          Mohon cek kembali data kelas kamar sebelum menyimpan formulir. Setelah
          berhasil disimpan, kelas kamar baru akan dibuat.
        </p>
        <div class="d-flex justify-content-end mt-4">
          <button
            class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
            style="width: 50%; border-radius: 26px"
            @click.prevent="handleCloseModal"
          >
            <span class="ml-2">Kembali</span>
          </button>
          <button
            @click.prevent="submitData"
            class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
            style="width: 50%; border-radius: 26px"
          >
            <span class="ml-2">Ya</span>
          </button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getRoomClass, createRoomClass } from "@/services/axios/api/rooms"
import { notification } from "ant-design-vue"
import { InfoCircleOutlined } from "@ant-design/icons-vue"
export default {
  components: {
    InfoCircleOutlined,
  },
  data: function () {
    return {
      jenisPerawatanOptions: [
        {
          label: "Rawat Non-Intensif",
          value: "Rawat Non-Intensif",
        },
        {
          label: "Rawat Intensif",
          value: "Rawat Intensif",
        },
      ],
      jenisPerawatan: "",
      namaKelasKamar: "",
      bhpRuangan: "",
      biayaAdministrasi: "",
      tarifKelasKamarUmum: "",
      tarifKelasKamarBPJS: "",
      tarifKelasKamarAsuransiLain: "",
      tarifPerawatanUmum: "",
      tarifPerawatanBPJS: "",
      tarifPerawatanLainnya: "",
      isNamaKelasKamarEmpty: true,
      isNamaKelasKamarExist: true,
      modalAddVisible: false,
      dataKamar: [],
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },

  methods: {
    handleAddModal() {
      this.modalAddVisible = true
    },
    handleCloseModal() {
      this.modalAddVisible = false
    },
    filterInput(type) {
      switch (type) {
        case "bhp":
          this.bhpRuangan = this.bhpRuangan.replace(/[^0-9]/g, "")

          break
        case "biaya":
          this.biayaAdministrasi = this.biayaAdministrasi.replace(/[^0-9]/g, "")

          break
        case "tr-umum":
          this.tarifKelasKamarUmum = this.tarifKelasKamarUmum.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tr-bpjs":
          this.tarifKelasKamarBPJS = this.tarifKelasKamarBPJS.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tr-lainnya":
          this.tarifKelasKamarAsuransiLain =
            this.tarifKelasKamarAsuransiLain.replace(/[^0-9]/g, "")

          break
        case "tp-umum":
          this.tarifPerawatanUmum = this.tarifPerawatanUmum.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tp-bpjs":
          this.tarifPerawatanBPJS = this.tarifPerawatanBPJS.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tp-lainnya":
          this.tarifPerawatanLainnya = this.tarifPerawatanLainnya.replace(
            /[^0-9]/g,
            "",
          )

          break

        default:
          break
      }
      // Menggunakan regex untuk hanya menerima angka
    },
    getName() {
      const name = localStorage.getItem("name")
      this.name = name
    },
    async fetchData() {
      const { data } = await getRoomClass()
      console.log(data.data)
      this.dataKamar = data.data
    },

    async submitData() {
      let isQualified = true

      this.isNamaKelasKamarEmpty = true
      this.isNamaKelasKamarExist = true

      if (!this.namaKelasKamar) {
        this.isNamaKelasKamarEmpty = false
        isQualified = false
      } else {
        const existingRoomClass = this.dataKamar.filter(
          (e) => e.name === this.namaKelasKamar,
        )
        if (existingRoomClass.length) {
          this.isNamaKelasKamarExist = false
          isQualified = false
        }
      }

      if (isQualified) {
        console.log(
          this.jenisPerawatan,
          this.namaKelasKamar,
          this.bhpRuangan,
          this.biayaAdministrasi,
          this.tarifKelasKamarUmum,
          this.tarifKelasKamarBPJS,
          this.tarifKelasKamarAsuransiLain,
          this.tarifPerawatanUmum,
          this.tarifPerawatanBPJS,
          this.tarifPerawatanLainnya,
        )
        const { data } = await createRoomClass(
          this.jenisPerawatan,
          this.namaKelasKamar,
          this.bhpRuangan,
          this.biayaAdministrasi,
          this.tarifKelasKamarUmum,
          this.tarifKelasKamarBPJS,
          this.tarifKelasKamarAsuransiLain,
          this.tarifPerawatanUmum,
          this.tarifPerawatanBPJS,
          this.tarifPerawatanLainnya,
        )
        // console.log(data)
        if (data.data.id) {
          notification.success({
            message: data.message,
            description: "",
            duration: 5,
          })
          this.$router.push("/kelas-kamar")
        }
      } else {
        notification.warning({
          message: "Gagal Membuat Pemeriksaan",
          description:
            "Inputan masih belum memenuhi requirement yang diperlukan",
          duration: 5,
        })
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
